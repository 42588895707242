/* Define a custom property for rotation */
@property --rotate {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
  }
  
  .h-15{
    height:15%;
  }
  .h-85{
    height:85%;
  }
  /* Multiplier Border Wrapper */
  .multiplier-border {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      max-width: 100vw;
      max-height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px; /* Adds space for the border */
      overflow: hidden; /* Prevents overflow */
  }
  
  /* Glowing Border Effect */
  .multiplier-border::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(var(--rotate), green, blue, purple, red);
      z-index: -1;
      animation: borderSpin 3s linear infinite;
      padding: 5px;
      max-width: 100vw;
      max-height: 100vh;
  }
    /* Rotation Animation */
    @keyframes borderSpin {
        0% { --rotate: 0deg; }
        100% { --rotate: 360deg; }
    }
    
  
  /* Rainbow Frenzy Mode */
  .border-rainbow::before {
      background-image: linear-gradient(var(--rotate), red, orange, yellow, green, blue, indigo, violet);
      animation: borderSpin 3s linear infinite;
    }
  /* Faster Animation for Rainbow Frenzy */
  @keyframes rainbowSpin {
      0% { background-position: 0% 50%; }
      50% { background-position: 100% 50%; }
      100% { background-position: 0% 50%; }
  }
  
  /* Question Container */
  .question-container {
    position: relative;
    background: white;
    padding: 20px;
    border-radius: 15px;
    text-align: center;
    z-index: 2;
    width: 98vw;
    height: 98vh;
    max-width: 98vw;
    max-height: 98vh;
    overflow: hidden;
  }
  
/* other colors */
/* Green Border */
.border-green::before {
    background-image: linear-gradient(var(--rotate), rgb(70, 243, 70), #0a0,rgb(5, 24, 5));
    animation: borderSpin 3s linear infinite;
}

/* Blue Border */
.border-blue::before {
    background-image: linear-gradient(var(--rotate), #00f, #0077ff,#040d18);
    animation: borderSpin 3s linear infinite;
}


/* Purple Border */
.border-purple::before {
    background-image: linear-gradient(var(--rotate), #800080, #a020f0 ,#1d031d);
    animation: borderSpin 3s linear infinite;
}

/* Orange Border */
.border-orange::before {
    background-image: linear-gradient(var(--rotate),  #ffa500, #dd9206, #0e0801);
    animation: borderSpin 3s linear infinite;
}


/* Red Border */
.border-red::before {
    background-image: linear-gradient(var(--rotate),  #b30707, #702727, #240a0a);
    animation: borderSpin 3s linear infinite;
}

/* Timer Bar Wrapper */
.timer-bar-wrapper {
  width: 90%;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.2); /* Subtle background for contrast */
  border-radius: 50px;
  overflow: hidden;
  margin: 10px auto; /* Centers horizontally and adds spacing */
  display: flex; 
  align-items: center;
  justify-content: flex-start; /* Aligns content to the left */
}


.score-info-wrapper {
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Left (Multiplier) and Right (Mute) containers get only as much space as they need */
.multiplier-wrapper,
.mute-wrapper {
  flex: 0 0 auto;
}

/* Center (Score) container takes remaining space and centers its text */
.score-wrapper {
  flex: 1;
  text-align: center;
}
/* Default styles */
.score-text,
.multiplier-text {
  font-size: 2rem;
  font-weight: bold;
  color: black;
}

.mute-button {
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: black;
}



/* ============================= */
.timer-bar {
    height: 100%;
    width: 100%;
    border-radius: 50px;
    transform-origin: left; /* Shrinks from right to left */

    /* Depletion: 30s, Gradient moves every 3s */
    animation: depleteBar linear forwards, gradientFlow 3s linear infinite;
    
    /* Force gradient to move */
    background-size: 500% 500%;
}

/* Depletes bar */
@keyframes depleteBar {
    from { width: 100%; }
    to { width: 0.5%; }
}

/* **Boosts Gradient Motion** */
@keyframes gradientFlow {
    0% { background-position: 0% 0%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 100%; }
}


/* Cool diagonal gradient effect */
.border-green .timer-bar { 
    background: linear-gradient(135deg, rgb(70, 243, 70), #0a0, rgb(14, 70, 14), rgb(70, 243, 70), #0a0);
    background-size: 400% 400%;
}
.border-blue .timer-bar { 
    background: linear-gradient(135deg, #00f, #0077ff, #0d2b50, #00f, #0077ff);
    background-size: 400% 400%;
}
.border-purple .timer-bar { 
    background: linear-gradient(135deg, #800080, #a020f0, #630a63, #800080, #a020f0);
    background-size: 400% 400%;
}
.border-orange .timer-bar { 
    background: linear-gradient(135deg, #ffa500, #dd9206, #3d2305, #ffa500, #dd9206);
    background-size: 400% 400%;
}
.border-red .timer-bar { 
    background: linear-gradient(135deg, #b30707, #702727, #4b1313, #b30707, #702727);
    background-size: 400% 400%;
}

/* **Rainbow Timer Bar (Diagonal Flow + Hue Shift + Depletion)** */
.border-rainbow .timer-bar {
    background: linear-gradient(135deg, red, orange, yellow, green, blue, indigo, violet, red, orange);
    background-size: 400% 400%;
    
    /* 🎯 Now includes depletion! */
    animation: depleteBar 30s linear forwards, hueShift 8s linear infinite, gradientFlow 6s linear infinite;
}
/* Hue Shift Animation */
@keyframes hueShift {
    0% { filter: hue-rotate(0deg); }
    100% { filter: hue-rotate(360deg); }
}


/* modal css  */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vW;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7); /* Dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's on top */
  }
  

    
  .quiz-card-image {
    max-height: 80%;
    max-width: 100%;
    object-fit: contain; /* Ensure the image fits within the container */
    width: auto;
    height: auto;
  
  }
      
  
  .button-size {
    font-size: 16px;
    height:100%;
    width: 100%;
  }
  
/* Blue Button Hover */
.kp-question-btn.btn-light-blue:hover {
  color:white;
  background-color: #044977;
  border-color: #044977;
}

/* Red Button Hover */
.kp-question-btn.btn-light-red:hover {
  color:white;
  background-color: #5e2731;
  border-color: #5e2731;
}

/* Yellow Button Hover */
.kp-question-btn.btn-light-yellow:hover {
  color:white;
  background-color: #5a5220;
  border-color: #5a5220;
}

/* Green Button Hover */
.kp-question-btn.btn-light-green:hover {
  color:white;
  background-color: #025317;
  border-color: #025317;
}
/* Ensure that the image and question sections take up 50% height on small screens */
.mh-50 {
  max-height: 50%;
}
.question-modal-image,
.question-modal-question {
  height: 80%;
}
.invisible-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0); /* Fully transparent */
  z-index: 100; /* Ensure it's on top of everything */
  
}
.no-hover {
  cursor: default; /* No pointer cursor */
}

.no-hover:hover {
  background-color: inherit; /* Prevent hover background color change */
  color: inherit; /* Keep text color unchanged */
  box-shadow: none; /* Remove hover box-shadow */
  border-color: inherit; /* Prevent border color change on hover */
}

.no-hover:focus {
  outline: none; /* Remove focus outline */
}

/* Adjust height when columns stack vertically on extra small screens */
@media (max-width: 1320px) {
  .question-modal-image,
  .question-modal-question {
    height: 50% !important;
  }
}
/* Prevent collapsing into a single column on landscape mobile */
/* Handle portrait mode: single-column layout */
@media screen and (max-width: 768px) and (orientation: portrait) {
  .kp-question-btn {
    width: 100%;  /* Ensure each button takes the full width in portrait mode */
  }
}

/* Handle landscape mode: 2x2 grid layout */
@media screen and (max-width: 768px) and (orientation: landscape) {
  .kp-question-btn {
    width: 100%;  /* Buttons take the full width within their column */
  }

  .quiz-modal-content .col-6, .quiz-modal-content .col-sm-6 {
    flex: 0 0 50%;  /* Enforce two columns in landscape mode */
  }
}
@media only screen and (orientation: portrait) {
  .question-modal-image, .question-modal-question {
    flex: 0 0 100%; /* Each column takes 100% width */
    max-width: 100%;
  }
}

/* Ensure 2-column layout in landscape mode */
@media only screen and (orientation: landscape) {
  .question-modal-image, .question-modal-question {
    flex: 0 0 50%; /* Each column takes 50% width */
    max-width: 50%;
  }
}
.position-relative {
  position: relative !important;/* Ensure this is the reference for positioning */
}
.score-wrapper {
  min-height: 80px; /* Ensure there's enough room for the score effect */
}
.score-effect {
  position: absolute; /* Take it out of the flow */
  top: 50%; /* Vertically center it */
  left: 50%; /* Horizontally center it */
  transform: translateX(-50%); /* Shift left by half of its own width to center it */
  color: gold;
  font-size: 2rem;
  font-weight: bold;
  animation: fadeDown 2s ease-out forwards;
  margin-top: 15px; /* Add space between the score and the effect */
}

@keyframes fadeDown {
  0% {
    transform: translateY(-5px) translateX(-50%); /* Keep horizontal translation */
    opacity: 1;
  }
  100% {
    transform: translateY(15px) translateX(-50%); /* Keep horizontal translation */
    opacity: 0;
  }
}
@keyframes rgbEffect {
  0% { color: red; }
  33% { color: green; }
  66% { color: blue; }
  100% { color: red; }
}

.frenzy-effect {
  animation: rgbEffect 1s infinite;
  font-weight: bold;
  text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.8);
}
.start-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  cursor: pointer;
  z-index: 1000;
}

.game-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.start-text {
  font-size: 1.5rem;
  opacity: 0.8;
}
.floating-text {
  position: absolute;
  font-size: 4rem;
  font-weight: bold;
  color: #ffdd57; /* Gold color */
  animation: fadeMove 1s ease-in-out;
  z-index: 10;
  pointer-events: none;
}

@keyframes fadeMove {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
}
/* Scale down for tablets and small screens */
@media (max-width: 900px) {
  .floating-text{
    font-size: 2.5rem;
  }
}

@media (max-width: 768px) {
  .floating-text{
    font-size: 2rem;
  }
  .score-text,
  .multiplier-text {
    font-size: 1.2rem; /* Slightly smaller */
  }

  .mute-button {
    font-size: 1.2rem;
  }
}

/* Further scale down for mobile phones */
@media (max-width: 480px) {
  .floating-text{
    font-size: 1.5rem;
  }

  .score-text,
  .multiplier-text {
    font-size: 1rem; /* Even smaller */
  }

  .mute-button {
    font-size: 1rem;
  }
}
